import { instance } from '@/utils/request'

// 用工企业列表
export function getCustomSysEntList(data){
  return instance({
    method: 'post',
    url: '/v1/sysEnt/getCustomSysEntList',
    data
  })
}
// 手动充值余额里面用工企业列表
export function getEmployersEntList(id){
  return instance({
    method: 'post',
    url: `/v1/sysEnt/getEmployersEntList/${id}`,
    
  })
}
// 手动充值余额里面下一步
export function balanceRecharge(data){
  return instance({
    method: 'post',
    url:'/v1/pAcctSon/balanceRecharge',
    data
  })
}

// // 上传文件
// export function batchImportEnt(fd){
//   return instance({
//     method: 'post',
//     url:'/v1/sysEnt/batchImportEnt',
//     fd
//   })
// }