<template>
  <!-- <article-detail :is-edit='true'></article-detail> -->
  <div class="editForm_content">
    <!-- 输入框 -->
    <div class="components-input">
      <div class="tinymceSchedule_title">
        <img src="../../../assets/bgm/09.png" alt="" />
        <p>用工企业</p>
      </div>
      <el-row :gutter="20" style="margin-top: 30px">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form :model="form" label-width="110px">
              <el-form-item label="用工企业名称：">
                <el-input
                  size="medium"
                  v-model="form.entName"
                  placeholder="请输入企业名称"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="110px">
              <el-form-item label="纳税识别号：">
                <el-input
                 size="medium"
                  v-model="form.idnNum"
                  placeholder="请输入纳税识别号"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="110px">
              <el-form-item label="关联发薪企业：" >
                <el-select
                 size="medium"
                  v-model="form.entId"
                  placeholder="全部"
                  style="width: 100%"
                   @change="changeEnterprises"
                   filterable
                 >
                 <el-option v-for="item in EnterprisesList" :key="item.id" :label="item.entName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-button type="primary" size="medium" @click="inquireData" class="button" icon="el-icon-search">
             查询</el-button>
            <el-button plain size="medium" @click="resetData" icon="el-icon-refresh-left">重置</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 按钮弹框 -->
    <div class="createForm_button">
      <el-button @click="clickEnterprise" size="medium" type="primary" icon="el-icon-circle-plus-outline" class="button"
        v-if=" $store.state['user']['PermissionCodeList'].includes('insertEmployers' )"
        >新增用工企业</el-button
      >
       <el-button type="primary" icon="el-icon-edit" class="button" @click="clickImportData" size="medium"
        v-if=" $store.state['user']['PermissionCodeList'].includes('batchImportEnt' )">导入用工企业</el-button>
      <el-button type="primary" icon="el-icon-edit" class="button" @click="clickExportData" size="medium"
      v-if=" $store.state['user']['PermissionCodeList'].includes('exportEmployers' )">导出</el-button>
      <!-- <el-button type="primary" icon="el-icon-plus" class="button" @click="clickBalance" size="medium" 
      v-if=" $store.state['user']['PermissionCodeList'].includes('manualRecharge' )">手动充值余额</el-button> -->
    </div>
    <!-- 表格 -->
    <div class="components-form">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loadingTable"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :header-cell-style="{ background: '#6591F1', color: '#fff' }"
        size="mini"
      >
        <el-table-column
          prop="entName"
          label="用工企业名称"
          align="center"
          show-overflow-tooltip
        >
          <template> </template>
        </el-table-column>
        <el-table-column
          prop="idnNum"
          label="纳税识别号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="法人姓名"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="num"
          label="关联发薪企业"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <p @click="enterpriseNum(row)" style="cursor: pointer;color:blue;">{{ row.num }}</p>
          </template>
        </el-table-column>

        <el-table-column
          prop="address"
          label="操作"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <!-- <el-button type="primary" size="small" plain @click="clickCompile(row)">编辑</el-button>
            <el-button type="primary" size="small" plain>详情</el-button> -->
              <el-tag style="cursor: pointer" size="small " @click="clickCompile(row)" 
               v-if=" $store.state['user']['PermissionCodeList'].includes('updateEmployers' )">编辑</el-tag >
              <!-- <el-tag style="cursor: pointer;margin-left: 5px;" size="small " @click="clickCompile(row)">详情</el-tag > -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <p>共 {{ total }} 条记录 第 {{ pageIndex }} 页</p>
        <el-pagination
          background
          @size-change="currentChange"
          @current-change="changePage"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[15, 30, 50, 100]"
          layout="total,prev, pager, next, sizes,jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 发薪企业数量 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="dialogCloseAudit"
    >
      <template slot="title">
        <div class="title">
          <p></p>
          <p>关联发薪企业</p>
        </div>
      </template>
      <h3 style="margin-left: 40px">
        翰德人力资源有限公司（91310115MA3K1313131)
      </h3>
      <el-form
        :model="formEnterprise"
        label-width="100px"
        style="margin-left: 40px; margin-top: 20px"
      >
        <el-form-item label="发薪企业名称:" prop="entName">
          <el-input
            size="medium"
            v-model="formEnterprise.entName"
            placeholder="请输入发薪企业名称"
            style="width: 70%"
          ></el-input>
          <el-button size="medium" @click="inquireEnterprise" class="button" type="primary" style="margin-left: 20px"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item label="纳税识别号:" prop="idnNum">
          <el-input
           size="medium"
            v-model="formEnterprise.idnNum"
            placeholder="请输入纳税识别号"
            style="width: 70%"
          ></el-input>
          <el-button size="medium" @click="resetDialogData" style="margin-left: 20px"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="entTableData"
        style="width: 100%"
        :header-cell-style="{ background: '#6591F1', color: '#fff' }"
        size="mini"
      >
        <el-table-column
          prop="entName"
          label="发薪企业名称"
          align="center"
          show-overflow-tooltip
        >
          <template> </template>
        </el-table-column>
        <el-table-column
          prop="idnNum"
          label="纳税识别号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="法人姓名"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
         <el-table-column
          prop="payChan"
          label="支付通道"
          align="center"
          show-overflow-tooltip
        >
        <template  slot-scope="{ row }">
          <p v-if="row.payChan == false "> - </p>
          <p v-else>平安银行</p>
        </template>
        </el-table-column>
         <el-table-column
          prop="subAccount"
          label="充值账号"
          align="center"
          show-overflow-tooltip
        >
        <template  slot-scope="{ row }">
          <p v-if="row.subAccount"> {{row.subAccount}}</p>
          <p v-else> -</p>
        </template>
        </el-table-column>
          <el-table-column
          prop="balance"
          label="账户余额"
          align="center"
          show-overflow-tooltip
        >
        <template  slot-scope="{ row }">
          <p v-if="row.balance"> {{row.balance}}</p>
          <p v-else>-</p>
        </template>
        </el-table-column>
      </el-table>
       <!-- 分页 -->
      <div class="pagination">
        <p>共 {{ entTotal }} 条记录 第 {{ entPageIndex }} 页</p>
        <el-pagination
          background
          @size-change="handelCurrentChange"
          @current-change="handelChangePage"
          :current-page="entPageIndex"
          :page-size="entPageSize"
          :page-sizes="[15, 30, 50, 100]"
          layout="total,prev, pager, next, sizes,jumper"
          :total="entTotal"
        >
        </el-pagination>
      </div>
      <div class="line"></div>
      <el-button @click="dialogCloseAudit">取 消</el-button>
    </el-dialog>

    <!-- 新增用工企业 -->
    <el-dialog
      :visible.sync="dialogEnterprise"
      width="30%"
      :before-close="dialogCloseEnterprise"
      v-if="dialogEnterprise"
    >
      <template slot="title">
        <div class="title">
          <p></p>
          <p>{{title}}用工企业</p>
        </div>
      </template>
       <el-form
        :model="formInformation"
        :rules="rulesInformation"
        ref="formInformation"
        label-width="130px"
        style="margin-left: 0px; margin-top: 10px"
      >
        <el-form-item label="用工企业名称:" prop="entName">
          <el-input
           size="medium"
            v-model="formInformation.entName"
            placeholder="请输入企业名称"
            style="width: 70%"
          ></el-input>
        </el-form-item>
        <el-form-item label="纳税识别号:" >
          <el-input
            size="medium"
            v-model="formInformation.idnNum"
            placeholder="请输入纳税识别号"
            style="width: 70%"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人姓名:" >
          <el-input
            size="medium"
            v-model="formInformation.name"
            placeholder="请输入法人姓名"
            style="width: 70%"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联发薪企业:" prop="entIds">
           <el-select
           size="medium"
            multiple 
            v-model="formInformation.entIds"
            placeholder="全部"
            style="width: 70%"
            @change="changeInformation"
            filterable
          >
          <el-option v-for="item in EnterprisesList" :key="item.id" :label="item.entName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
       </el-form>
      <div class="line"></div>
      <el-button
        class="button"
        type="primary"
        @click="newlyEnterprise"
        :disabled="loadingEnterprise"
        >{{title}}</el-button>
      <el-button @click="dialogCloseEnterprise">取 消</el-button>
    </el-dialog>
    <!-- 手动充值余额 -->
     <el-dialog
      :visible.sync="dialogBalance"
      width="30%"
      :before-close="dialogCloseBalance"
    >
      <template slot="title">
        <div class="title">
          <p></p>
          <p>手动充值余额</p>
        </div>
      </template>
       <el-form
        ref="formBalance"
        :model="formBalance"
        :rules="rulesFormBalance"
        label-width="130px"
        style="margin-left: 0px; margin-top: 20px"
      >
       <el-form-item label="关联发薪企业:" prop="entId">
          <el-select
           size="medium"
            v-model="formBalance.entId"
            placeholder="全部"
            style="width:70%"
            @change="chanegBalance"
            filterable
          >
           <el-option v-for="item in EnterprisesList" :key="item.id" :label="item.entName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择用工企业:" prop="entName">
          <el-select
          size="medium" 
            v-model="formBalance.entName"
            placeholder="全部"
            style="width:70%"
            @change="chanegBalanceId"
            filterable
          >
           <el-option v-for="item in EmployersEntList" :key="item.id" :label="item.entName" :value="item.id"></el-option>
         
          </el-select>
        </el-form-item>
       
         <el-form-item label="充值余额:" prop="money">
          <el-input
          size="medium"
            min="0"
            type="number"
            v-model="formBalance.money"
            placeholder="请输入充值余额"
            style="width:70%"
           @input="valueChange"
          ></el-input>
        </el-form-item >
        <el-form-item  label="转账附言:" prop="useEx">
          <el-input
            size="medium"
            type="textarea"
            :rows="2"
            placeholder="请输入转账附言"
            v-model="formBalance.useEx"
            maxlength="40"
            show-word-limit
             style="width:70%">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="line"></div>
      <el-button
        class="button"
        type="primary"
        @click="newlyBalance"
        :loading="loadingBalance"
        >下一步</el-button>
      <el-button @click="dialogCloseBalance">取 消</el-button>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog
      :visible.sync="dialogImport"
      width="540px"
      v-if="dialogImport"
      >
      <template slot="title">
        <div class="title">
          <p></p>
          <p>导入用工企业</p>
        </div>
      </template>
      <div style="display: flex;margin-left:130px">
              <p class="Eclipse">下载用工企业导入模板</p>
              <el-button
                type="primary"
                size="medium"
                style="background: #6591f1"
                @click="download"
              >
                点击下载</el-button
              >
            </div>
         <el-form
          :model="formImport"
          :rules="rulesImport"
          ref="formImport"
          label-width="130px"
          style="margin-left: 0px; margin-top: 10px"
          >
        <el-form-item label="关联发薪企业:" prop="entIds">
           <el-select
            size="medium"
            multiple 
            v-model="formImport.entIds"
            placeholder="全部"
            style="width: 90%"
            @change="changeImport"
            filterable
          >
          <el-option v-for="item in EnterprisesList" :key="item.id" :label="item.entName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="导入用工企业:" prop="files">
          <el-upload
            class="upload-demo"
            ref="upload"
            drag
            :show-file-list="true"
            :before-upload="beforeFileUpload"
            :http-request="uploadFile"
            :on-change="handleChange"
            :before-remove="beforeRemoves"
            :on-remove="fileRemove"
            :file-list="formFileLists"
            :auto-upload="false"
            action=""
          
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
          </el-upload>
        </el-form-item>
       </el-form>
      <div class="line"></div>
      <el-button
        class="button"
        type="primary"
        @click="newlyImport"
        :disabled="loadingImport"
        >确定</el-button>
      <el-button @click="dialogCloseImport">取 消</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { getCustomSysEntList,getEmployersEntList,balanceRecharge,batchImportEnt } from '@/api/payMaster'
import { getSalaEntPageList,relatedSalaryPayingEnterprises,saveOrUpdateCustomSysEnt } from '@/api/lssuingModule'
import { instance } from '@/utils/request'
export default {
  name: 'editForm',
  data() {
    return {
      loadingTable:false,
      dialogImport:false,
      loadingImport:false,
      formFileLists:[],
      timers:null,
      form: {
        entName: '',
        idnNum: '',
        entId:''

      },
      //关联数量
      formEnterprise: {
        entName:'',
        idnNum:''
      },
      formInformation:{ //新增发薪企业基本信息
        entName:'',
        idnNum:'',
        name:'',
        entIds:[]
      },
      EnterprisesList:[],
      // entIds:[],
      rulesInformation:{
        entName:[
           { required: true,message: '请输入用工企业名称', trigger: ['blur', 'change'] },
        ],
        idnNum:[
           { required: true,message: '请输入纳税识别号', trigger: ['blur', 'change'] },
        ],
        name:[
           { required: true,message: '请输入法人姓名', trigger: ['blur', 'change'] },
        ],
        entIds:[
           { required: true,message: '请选择发薪企业', trigger: ['blur', 'change'] },
        ],
      },
      formImport:{
        entIds:'',
        files:''
      },
      rulesImport:{
         entIds:[
           { required: true,message: '请选择发薪企业', trigger: ['blur', 'change'] },
        ],
        files:[
            { required: true, message: '请上传文件', trigger: ['blur', 'change'] }
        ]
      },
      timers:null,
      id:'',
      title:'',
      EnterpriseObj:{},
      tableData: [],
      entTableData:[],
      total: 0,
      pageSize: 15,
      pageIndex: 1,
      dialogVisible: false,
      dialogEnterprise:false,
      loadingEnterprise: false,
      loading: false,
      entTotal:0,
      entPageIndex:1,
      entPageSize:10,
      dialogBalance:false,//手动充值余额弹框
      formBalance:{ //手动充值弹框表单
         entId:'',
         entName:'',
         money:'',
         useEx:'',
         humanEntId:'',//发薪企业id
         enterpriseId:''
      },
      rulesFormBalance:{
        entId:[
           { required: true,message: '请选择发薪企业', trigger: ['blur', 'change'] },
        ],
        entName:[
           { required: true,message: '请选择用工企业', trigger: ['blur', 'change'] },
        ],
        money:[
           { required: true,message: '请输入充值金额', trigger: ['blur', 'change'] },
           {
            pattern: /^[+-]?(\d|([1-9]\d+))(\.\d+)?$/, message: '金额仅为数字', trigger: 'blur'
          }
        ],
         useEx:[
           { required: true,message: '请输入转账附言', trigger: ['blur', 'change'] },
            {
            max: 40,
            message: '转账附言最多不能超过40位',
            trigger: ['blur', 'change']
          },
        ],
      },
      loadingBalance:false,
      EmployersEntList:[],
      rules:{}


    }
  },
  mounted() {
    this.getList()
    this.getEnterprisesList()
  },
  methods: {
    download() {
        if (this.timers) {
            clearTimeout(this.timers);
        }
        this.timers = setTimeout(() => {
           let load ="https://enclosure.jinhuasuan.cn/file/excel/batch_import_ent.xlsx";
          window.location.href = load;
        }, 500);
    },
    //导入
    clickImportData() {
      this.dialogImport = true
       this.$nextTick(() => {
        if(this.$refs.formImport){
          this.$refs.formImport.resetFields();//个人喜爱。clearValidate有时候验证清不掉，具体原因暂时没找到
        } 
      })
       for(let key in this.formImport){
           this.formImport[key]="";  //注意初始值的数据类型为数字、数组等其他类型的，需要在循环外补充。
      }
    },
    changeImport (val) {
       this.formImport.entIds = val
    },
      // 上传文件之前
    beforeFileUpload(file) {
      const fileSize = file.size / 1024 / 1024 < 30;
      if (!fileSize) {
        this.$message.error("上传文件大小不能超过30MB");
        return false;
      }
    },
    //上传
    uploadFile(file) {
         const formData = new FormData();
          formData.append("file", file.file);
          formData.append("salaId",  this.formImport.entIds);
          instance({
            method: "post",
            url: "/v1/sysEnt/batchImportEnt",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data; charset=UTF-8",
            },
          }).then((res)=>{
           
            if ( res.success) {
              this.$message.success("上传文件成功");
               this.getList()
               this.formImport.entIds = ''
               this.formImport.files = ''
               this.dialogImport = false
            }

          })
    },
    handleChange (uploadFile, fileList) {
        this.formImport.files = uploadFile
        if (fileList.length > 1) {
          fileList.splice(0,1)
        }
        if (fileList.length !== 0) {
            this.$refs.formImport.validateField('files')
        }
        
    },
    fileRemove(uploadFile, fileList) {
        if (fileList.length === 0) {
            this.formImport.files = null
            this.$refs.formImport.validateField('files')
        }
      },
    beforeRemoves(){

    },
    dialogCloseImport (){
      this.dialogImport = false
      this.loadingImport = false
    },
    //确定
    newlyImport () {
       this.loadingImport = true
       setTimeout(() => {
        this.loadingImport = false
       }, 10000);
       this.$refs.formImport.validate(valid => {
         if (valid) {
               if (this.timers) {
                  clearTimeout(this.timers);
                }
                this.timers = setTimeout(() => {
                  this.$refs.upload.submit();
                  this.loadingImport = false
                }, 500);
               
         }
       })
    },

    //整个列表限制
    async getList(  ) {
      this.loadingTable = true
      setTimeout(() => {
        this.loadingTable = false;
      }, 60000);
      const res = await getCustomSysEntList({
        entId:this.form.entId,
        entName: this.form.entName,
        idnNum: this.form.idnNum,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      })
      if (res.success) {
        this.loadingTable = false
        this.tableData = res?.data?.list
        this.total = res?.data?.total
      } else {
         this.loadingTable = false
        //  this.$message.error(res.message)
      }
    },
    // 关联发薪企业列表
    async getEnterprisesList () {
      const res = await relatedSalaryPayingEnterprises()
      if ( res.success ) {
          this.EnterprisesList = res?.data
         
      }
      
    },
    changeEnterprises ( val ) {
      this.form.entId = val
    },
    //查询
    inquireData() {
      this.getList()
    },
    //重置
    resetData() {
      this.form.entName = ''
      this.form.idnNum = ''
      this.form.entId = ''
      this.pageIndex = 1
      this.getList()
    },

    currentChange(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getList()
    },
    changePage(val) {
      this.pageIndex = val
      this.getList()
    },

    //导出
    clickExportData () {
      setTimeout(() => {
        this.toExportExcel();
      }, 500);
    },
    toExportExcel () {
      instance({
          method:'POST',
          url:'/v1/sysEnt/customSysEntListWrite',
          data:{
            entId:this.form.entId,
            entName: this.form.entName,
            idnNum: this.form.idnNum,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
          },
          responseType: 'blob'
        }).then( res => {
            const link = document.createElement("a");  //创建a标签
            let blob = new Blob([res], { type: "application/vnd.ms-excel" }); // response就是接口返回的文件流
            let objectUrl = URL.createObjectURL(blob);
            link.href = objectUrl;
            link.download = "用工企业列表"; // 自定义文件名
            link.click(); // 下载文件
            URL.revokeObjectURL(objectUrl); // 释放内存
        })
      },
    //点击列表里面关联企业数量按钮
     enterpriseNum(row) {
      this.EnterpriseObj = row
      this.dialogVisible = true
      this.getEnterpriseList()
    },
    //发薪企业列表数据查询
    inquireEnterprise () {
      this.getEnterpriseList()
    },
     //发薪企业列表数据重制
    resetDialogData () {
       this.formEnterprise.idnNum = ''
       this.formEnterprise.entName =''
       this.getEnterpriseList()
    },
    //发薪企业列表数据
    async getEnterpriseList () {
       const res = await getSalaEntPageList({
        entId: this.EnterpriseObj.id,
        entName:this.formEnterprise.entName,
        idnNum:this.formEnterprise.idnNum,
        pageIndex: this.entPageIndex,
        pageSize: this.entPageSize,
        payChan: 1,
      })
     if (res.success ) {
       this.entTableData = res?.data?.list
       this.entTotal = res?.data?.total
     }
    },
     //关联企业数据分页
    handelCurrentChange ( val ) {
      this.entPageSize = val
      this.entPageIndex = 1
      this.getEnterpriseList()
    },
     //关联企业分页
    handelChangePage ( val ) {
       this.entPageIndex = val
      this.getEnterpriseList()
    },
    newlyData() {

    },
    //取消
    dialogCloseAudit() {
      this.dialogVisible = false
    },
   valueChange ( e ) {

        // 小数点后面保留2位
        this.formBalance.money = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
        // console.log( e,'sss');
   },
   
     // 新增用工企业按钮
    clickEnterprise () {
      console.log( this.formInformation,' this.formInformation');
      this.title = '新增'
      this.id = ''
      this.dialogEnterprise = true

       this.$nextTick(() => {
        if(this.$refs.formInformation){
          this.$refs.formInformation.resetFields();//个人喜爱。clearValidate有时候验证清不掉，具体原因暂时没找到
        } 
      })
       for(let key in this.formInformation){
           this.formInformation[key]="";  //注意初始值的数据类型为数字、数组等其他类型的，需要在循环外补充。
        
      }
    },

     // 新增用工企业按钮
    clickCompile ( row ) {
      this.title = '编辑'
      this.id = row.id
      this.dialogEnterprise = true
      this.formInformation.entName = row.entName
      this.formInformation.idnNum = row.idnNum
      this.formInformation.name = row.name
       this.EnterprisesList.forEach ( item => {
        if (row.entIds.indexOf(item.id) != -1) {
             this.formInformation.entIds = row.entIds
        }
      })
      // this.$nextTick(() => {
      //   this.$refs.formInformation.resetFields();
      // })
    },
    changeInformation ( val ) {
      this.formInformation.entIds = val
    },
    //新增用工弹框按钮
    newlyEnterprise () {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.$refs.formInformation.validate( async valid => {
           if ( valid ) {
             this.loadingEnterprise = true
              const res = await saveOrUpdateCustomSysEnt({
                entIds: this.formInformation.entIds,
                entName: this.formInformation.entName,
                id:this.id,
                idnNum:this.formInformation.idnNum,
                name: this.formInformation.name
              })
              if ( res.success ) {
                if ( this.title == '新增') {
                    this.$message.success('新增成功')
                } else {
                   this.$message.success('编辑成功')
                }
              
                this.loadingEnterprise = false
                this.dialogEnterprise = false
  
                this.getList()
                this.formInformation.name =''
              } else {
                this.loadingEnterprise = false
                // this.$message.error(res.message)
              }
           }
        })
      }, 500);
    },
    //关闭新增发薪取消弹框
    dialogCloseEnterprise () {
       this.dialogEnterprise = false
    },
    //手动充值余额选择发薪企业
    async chanegBalance ( val ) {
      this.formBalance.humanEntId = val
      const res = await getEmployersEntList(val)
      if ( res.success ) {
           this.EmployersEntList = res?.data
      }
     
    },
    chanegBalanceId (val ) {
      this.formBalance.enterpriseId = val
    },
    //手动充值余额按钮
    clickBalance () {
      this.dialogBalance = true
        this.$nextTick(() => {
        if(this.$refs.formBalance){
          this.$refs.formBalance.resetFields();//个人喜爱。clearValidate有时候验证清不掉，具体原因暂时没找到
        } 
      })
       for(let key in this.formBalance){
           this.formBalance[key]="";  //注意初始值的数据类型为数字、数组等其他类型的，需要在循环外补充。
      }

    },
     //手动充值余额下一步按钮
    newlyBalance () {
    
     this.$refs.formBalance.validate(async valid => {
       if ( valid) {
          this.loadingBalance = true
          const res = await balanceRecharge({
              entId: this.formBalance.enterpriseId,
              humanEntId:this.formBalance.humanEntId,
              tranAmount:this.formBalance.money,
              type: 0,
              useEx: this.formBalance.useEx,
          })
         if ( res.success ) {
             this.loadingBalance = false
             this.$message.success('充值成功')
             this.dialogBalance = false
            //  this.getList()
         } else {
           this.loadingBalance = false
          //  this.$message.error(res.message)
         }
       }
     } )
    },
    //手动充值余额取消按钮
    dialogCloseBalance () {
      this.dialogBalance = false
    }
  }
}
</script>
<style lang="less" scoped>
.editForm_content {
  padding: 20px;
  .components-input {
    width: 100%;
    height: 169px;
    background: #ffffff;
    padding: 20px;
    .tinymceSchedule_title {
      width: 100%;
      border-bottom: 1px solid #ececec;
      display: flex;
      height: 40px;
      img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        // line-height: 22px;
      }
      p:first-child {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin-left: 10px;
      }
    }
    // padding: 20px;
  }
  .createForm_button {
    width: 100%;
    height: 92px;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    .el-button {
      margin-top: 10px;
    }
  }
  .components-form {
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .title {
    display: flex;
    border-bottom: 1px solid #ececec;
    height: 40px;
    p:first-child {
      width: 3px;
      height: 13px;
      background: #6591f1;
      margin-top: 5px;
    }
    p:last-child {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: #333333;
      line-height: 22px;
      margin-left: 10px;
    }
  }
  .button {
    background-color: #6591f1;
  }
   .line {
    border-bottom: 1px solid #ECECEC;
    margin: 20px 0px;
  }
  .pinganbank {
    width: 141px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 700;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
  }
   /deep/ .el-input__inner {
    background: #f7f8fa;
    border: none;
  }
   .Eclipse {
    background: #f7f9fe;
    height: 36px;
    margin-right: 22px;
    padding: 8px 30px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
}
</style>
